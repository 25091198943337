/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  scroll-behavior: smooth;
}

main {
  margin-bottom: 100px;
}

.site-header .mobile-open {
  right: 29px;
}

.site-header .mobile-close {
  right: 29px;
}

.site-header .container {
  padding: 0 24px;
}

.site-nav ul li.menu-item a.active {
  color: #1964f9;
}

.trademark-preview {
  padding: 0 16px;
  font-weight: bold;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  position: relative;
  background-color: #efefef;
  overflow: auto;
}

.drop-zone {
  color: #BDC1C7;
  /* min-height: 200px !important; */
  align-items: center;
  display: flex;
  justify-content: center;
}

.drop-zone.error {
  border-color: red;
}

.drop-zone .drop-zone-paragraph {
  margin-top: 8px;
  ;
  margin-bottom: 8px;
}

.drop-zone .preview-container {
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  background: white;
  top: 0;
  bottom: 0;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
}

.drop-zone .preview-item img {
  height: auto;
  max-height: 224px;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .faqs-accordion_content{
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
}

.faqs-accordion_content.active{
  height: auto;
  display: block;
} */
#post-0{
  background-image: url(/src/images/blog/vadar.jpg);
}
#post1{
  background-image: url(/src/images/blog/post1.jpg);
}
#post2 {
  background-image: url(/src/images/blog/post2.jpg);
}
#post3 {
  background-image: url(/src/images/blog/post3.png);
}
#post4 {
  background-image: url(/src/images/blog/post4.jpg);
}
#post5 {
  background-image: url(/src/images/blog/post5.png);
}
#post6 {
  background-image: url(/src/images/blog/post6.png);
}
.gutter{
  margin: 0 16px;
}
.text-center{
  text-align: center;
}
.banner p{
  max-width: 615px;
}
.hero-content h1{
  font-size: 44px;
}
.site {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  flex-shrink: 0;
  background-color: #037cf5;
}

.site-content {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
  background-color: #134c7d;
}