.pricing-table {
  background-color: #e7edf0;
}

.pricing-table .pricing-table-header {
  padding: 20px;
  font-weight: bold;
  font-size: 24px;
  background: #1964f9;
  color: #fff;
}

.pricing-table .pricing-row {
  padding: 20px;
  display: flex;
  border-bottom: 1px solid white;
}

.pricing-table .pricing-row .col-1 {
  width: 160px;
}

.pricing-table .pricing-row .col-2 {
  font-size: 20px;
  width: 230px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.pricing-table .pricing-row .col-1 .pricing-number {
  font-size: 48px;
}

.pricing-table .pricing-row .col-1 span {
  font-size: 13px;
  display: block;
  margin-top: 4px;
}